.markdown {
}

.markdown h3 {
  @apply font-semibold font-graphie text-base leading-6 text-color-black m-0 mb-2;
}

.markdown p {
  @apply m-0 mb-6 text-color-grey-600 font-graphie font-normal text-base leading-6;
}

.markdown ul {
  @apply marker:text-brown font-graphie text-base font-normal leading-6 text-color-grey-600 m-0 mb-6 pl-[1.125rem];
}

.markdown li:not(:last-child) {
  @apply mb-1;
}

.markdown a {
  @apply text-dimgray whitespace-nowrap hover:text-brown;
}
