@import url("https://use.typekit.net/ajo6bde.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply leading-[normal] m-0;
}

*,
::before,
::after {
  border-width: 0;
}

::-webkit-scrollbar {
  @apply w-1 h-1;
}
::-webkit-scrollbar-button {
  @apply w-0 h-0;
}
::-webkit-scrollbar-thumb {
  @apply bg-color-black border-none border-0 border-white rounded-3xl;
}
::-webkit-scrollbar-thumb:hover {
  @apply bg-color-black;
}
::-webkit-scrollbar-thumb:active {
  @apply bg-color-black;
}
::-webkit-scrollbar-track {
  @apply bg-white border-none border-0 border-white rounded-3xl;
}
::-webkit-scrollbar-track:hover {
  @apply bg-white;
}
::-webkit-scrollbar-track:active {
  @apply bg-white;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
